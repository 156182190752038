<template>
  <div>
    <div class="header">
      <video
        width="100%"
        src="https://enway-official.s3.ap-northeast-1.amazonaws.com/enway-video/2-1/Phantas-Rendering-Video.dfc9005c.mp4"
        controls
        autoplay
        muted
        loop
        playsinline
        webkit-playsinline
        x5-playsinline
      ></video>
    </div>
    <ul class="introduce">
      <li>
        <p class="title">{{ this.$t("phantas.SmartObstacle") }}</p>
      </li>
      <li>
        <p class="title">{{ this.$t("phantas.OneStop") }}</p>
      </li>
      <li class="otherTitle">
        <p class="title">
          <span>{{ this.$t("phantas.AutoSpot") }}</span>
          <span>{{ this.$t("phantas.QuadrupleEfficiency") }}</span>
        </p>
      </li>
    </ul>
    <div class="footer">
      <img src="../../assets/phantas/S1.5b77b7c0.png" alt="" />
      <p class="footer-title">{{ this.$t("phantas.PhantasS1") }}</p>
      <ul class="config">
        <li>
          <p>{{ this.$t("phantas.ProductSize") }}</p>
          <p>540（L）* 440（W）* 617（H）mm</p>
        </li>
        <li>
          <p>{{ this.$t("phantas.Products") }}</p>
          <p>48kg</p>
        </li>
        <li>
          <p>{{ this.$t("phantas.SensorConfiguration") }}</p>
          <p>{{ this.$t("phantas.use") }}</p>
        </li>
        <li>
          <p>{{ this.$t("phantas.KiyoaraiWidth") }}</p>
          <p>330 mm</p>
        </li>
      </ul>
    </div>
    <div class="pleonasm">
      <h1 v-html="getHtml('phantas.pleonasm_title')"></h1>
      <p v-html="getHtml('phantas.pleonasm_content')"></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    getHtml(value) {
      return this.$t(value);
    },
  },
};
</script>

<style scoped lang="less">
.header {
  video {
    vertical-align: bottom;
  }
}
.introduce {
  width: 100vw;
  li {
    width: inherit;
    height: 48.5938vw;
    background-size: contain !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    vertical-align: bottom;
    .content {
      text-align: right;
      font-size: 12px;
      font-family: "Meiryooo";
      font-weight: bold;
      line-height: 17px;
      color: #404040;
    }
    .title {
      width: 62.5vw;
      margin: 0 7vw;
      font-size: 4vw;
      font-family: "Meiryooo";
      font-weight: bold;
      line-height: 3.0729vw;
      color: #ffffff;
      span {
        font-family: "Meiryooo";
      }
      span:first-child {
        font-size: 12px;
        font-weight: bold;
        line-height: 17px;
        color: #404040;
      }
      span:last-child {
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        color: #000000;
        span {
          color: #0064e6;
        }
      }
    }
  }
  li:first-child {
    background: url("../../assets/phantas/Smart-Obstacle-Avoidance-D.-min.7b920454.png")
      no-repeat center;
  }
  li:nth-child(2) {
    background: url("../../assets/phantas/Banner-Phantas-D.-1.png") no-repeat center;
  }

  li:last-child {
    background: url("../../assets/phantas/Smart-Obstacle-Avoidance-D.-1.497800e5.png")
      no-repeat center;
    align-self: end;
  }
  li:last-child .title {
    width: 41.6vw;
    height: 15.2vw;
    color: #000;
    // align-self: end;
    // display: flex;
    // flex-direction: column;
    justify-content: space-between;
  }
  .otherTitle {
    display: flex;
    align-items: flex-end;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10.6667vw;
  img {
    flex-shrink: 0;
    width: 38.9333vw;
  }
  .footer-title {
    font-size: 3.7333vw;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 5.0667vw;
    color: #000000;
    margin-top: 14.6667vw;
  }
  .config {
    margin-top: 4vw;
    li {
      display: flex;
      margin: 0 9.0667vw;
      margin-bottom: 2.6667vw;
      p {
        font-size: 12px;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 17px;
      }
      p:first-child {
        flex-shrink: 0;
        color: #000000;
        width: 24vw;
        text-align: right;
      }
      p:last-child {
        flex-grow: 1;
        margin-left: 2.6667vw;
        color: #0064e6;
      }
    }
    li:last-child {
      margin-bottom: 10.6667vw;
    }
  }
}
.pleonasm {
  height: 85.3333vw;
  background: url("../../assets/phantas/mo-last.png") no-repeat center;
  background-size: cover;
  color: #fff;
  overflow: hidden;
  padding: 0 4.2667vw;
  h1 {
    margin-top: 8vw;
    font-size: 3.7333vw;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 5.0667vw;
    margin-bottom: 11.2vw;
  }
  p {
    font-size: 3.2vw;
    font-family: "Meiryooo";
    font-weight: 400;
    line-height: 5.3333vw;
  }
}
</style>
