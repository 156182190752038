<template>
  <div class="phantas">
    <div class="video">
      <video
        src="https://enway-official.s3.ap-northeast-1.amazonaws.com/enway-video/2-1/Phantas-Rendering-Video.dfc9005c.mp4"
        controls
        autoplay
        muted
        loop
        width="100%"
      ></video>
    </div>
    <div class="introduce">
      <div class="detailed detailed1">
        <div class="left container">
          <div class="content">
            <h3></h3>
            <h4>{{ this.$t("phantas.SmartObstacle") }}</h4>
            <p>
              {{ this.$t("phantas.PhantasDetects") }}
            </p>
          </div>
        </div>
      </div>
      <div class="detailed detailed2">
        <div class="left container">
          <div class="content white">
            <h3></h3>
            <h4>{{ this.$t("phantas.OneStop") }}</h4>
            <p>
              {{ this.$t("phantas.WhenCombined") }}
            </p>
          </div>
        </div>
      </div>
      <div class="detailed detailed3">
        <div class="right container">
          <div class="content">
            <h3>{{ this.$t("phantas.AutoSpot") }}</h3>
            <h4>{{ this.$t("phantas.QuadrupleEfficiency") }}</h4>
            <p>
              {{ this.$t("phantas.PhantasExplores") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="parameter container">
      <img class="photo" src="../../assets/phantas/S1.5b77b7c0.png" alt="" />
      <div class="message">
        <h4 class="title">{{ this.$t("phantas.PhantasS1") }}</h4>
        <p>
          <span class="textRight">{{ this.$t("phantas.ProductSize") }}</span>
          <span>540（L）* 440（W）* 617（H）mm</span>
        </p>
        <p>
          <span class="textRight">{{ this.$t("phantas.Products") }}</span>
          <span>46 kg</span>
        </p>
        <p>
          <span class="textRight">{{
            this.$t("phantas.SensorConfiguration")
          }}</span>
          <span>{{ this.$t("phantas.use") }}</span>
        </p>
        <p>
          <span class="textRight">{{ this.$t("phantas.KiyoaraiWidth") }}</span>
          <span>400-700 ㎡/h</span>
        </p>
      </div>
    </div>
    <div class="pleonasm">
      <h1 v-html="getHtml('phantas.pleonasm_title')"></h1>
      <p v-html="getHtml('phantas.pleonasm_content')"></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    getHtml(value) {
      return this.$t(value);
    },
  },
};
</script>

<style scoped lang='less'>

.phantas {
  video {
    vertical-align: middle;
    background-color: pink;
  }
  .introduce {
    .container {
      display: flex;
      align-items: center;
      height: 700px;
      .white {
        color: #fff;
      }
      h3 {
        width: 512px;
        font-size: 30px;
      }
      h4 {
        width: 512px;
        font-size: 44px;
        font-weight: 700;
      }
      p {
        margin-top: 40px;
        display: inline-block;
        width: 512px;
        font-size: 16px;
      }
    }
    .left {
      display: flex;
      justify-content: flex-start;
    }
    .right {
      color: #000000;
      display: flex;
      justify-content: flex-end;
    }
    .detailed {
      background: no-repeat center;
      background-size: cover;
      .content{
        p:last-child{
          line-height: 27px;
        }
      }
    }
    .detailed1 {
      background-image: url("~@/assets/phantas/Smart-Obstacle-Avoidance-D.-min.7b920454.png");
    }
    .detailed2 {
      background-image: url("~@/assets/phantas/Banner-Phantas-D.-1.png");
    }
    .detailed3 {
      background-image: url("~@/assets/phantas/Smart-Obstacle-Avoidance-D.-1.497800e5.png");
    }
  }
  .parameter {
    width: 1200px;
    height: 647px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .photo {
      flex-shrink: 0;
      width: 290px;
      height: 347px;
    }
    .message {
      display: flex;
      flex-direction: column;
      height: 347px;
      justify-content: space-evenly;
      h4 {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 100px;
      }
      p {
        font-size: 16px;
        color: #000;
        line-height: 54px;
        display: flex;

        .textRight {
          flex-shrink: 0;
          width: 210px;
          text-align: right;
          color: #000;
        }
        span {
          color: #0064e6;
          line-height: 24px;
        }
        span:last-child {
          flex-grow: 1;
          margin-left: 40px;
        }
      }
    }
  }
  .pleonasm {
    aspect-ratio: 1920/630;
    background: url("../../assets/phantas/ミッション.png") no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    color: #fff;
    justify-content: center;
    align-items: center;
    h1 {
      width: 1200px;
      font-size: 40px;
      font-family: 'Meiryooo';
      font-weight: bold;
      line-height: 60px;
      margin-bottom: 50px;
    }
    p {
      width: 1200px;
      font-size: 18px;
      font-family: 'Meiryooo';
      font-weight: 400;
      line-height: 30px;
    }
  }
  // 当屏幕宽度小于860px时
  @media screen and (max-width: 860px) {
    .introduce {
      h3 {
        width: 100% !important;
        font-size: 20px !important;
      }
      h4 {
        width: 100% !important;
        font-size: 30px !important;
      }
      p {
        width: 100% !important;
      }
      .content {
        padding: 0 10px;
        width: 100%;
      }
      .container {
        height: 400px !important;
      }
    }

    .parameter {
      padding: 30px 20px;
      width: unset !important;
      height: unset !important;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      h4 {
        font-size: 16px !important;
      }
      p {
        font-size: 18px !important;
      }
    }
  }
}
</style>
